<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Value Changes Diagram</strong>
						<br>
                    </CCardHeader>
                    <CCardBody>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('1', 'h')">Last Hour</CButton>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('12', 'h')">Last 12 Hour</CButton>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('24', 'h')">Last 24 Hour</CButton>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('1', 'w')">Last Week</CButton>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('1', 'M')">Last Month</CButton>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('3', 'M')">Last 3 Month</CButton>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('6', 'M')">Last 6 Month</CButton>
						<CButton color="info" class="mb-2 mr-2" size="sm" @click="get_chart('1', 'Y')">Last 1 Year</CButton>
						<br>
						<div id="chart" style="height: 400px; min-width: 310px"></div>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Process Details</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }" >
                        </CDataTable>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" @click="redirect_to(`/data/proccesses/${$route.params.id}/update`)" class="mr-2">Update</CButton>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
import Highcharts from 'highcharts';
export default {
	components: { Highcharts },
    data () {
        return {
			items: [],
			data: [],
			date_time: [],
        }
    },
    computed: {
        fields(){
            return [
                { key: 'key', label: ''},
                { key: 'value', label: ''}
            ]
        }
    },
    mounted(){
		this.get_chart();
		this.get();
    },
    methods: {
		get_chart(v='1', t='h'){
			this.data = [];
			this.date_time = [];
			this.axios.get('get_time/')
				.then(res => {
					let ago = this.$moment(res.data.current_date).subtract(v, t).format('YYYY-MM-DD HH:mm:ss');
					this.axios.get('arbitrage/output_datas/' 
						+ '?soft_delete=false&chart=true&input_proccess_id=' + this.$route.params.id
						+ '&created_at=' + ago)
						.then(res => {
							res.data.map(val => {
								this.data.push(val.v);
								this.date_time.push(this.$moment(val.c).format('YYYY-MM-DD HH:mm'));
							});
							Highcharts.chart('chart', {
								chart: {
									zoomType: 'x'
								},
								rangeSelector: {
									selected: 1
								},
								xAxis: {
									categories: this.date_time
								},
								series: [{
									name: 'Values',
									data: this.data,
								}],
								responsive: {  
									rules: [{  
										condition: {  
											maxWidth: 500  
										},  
										chartOptions: {  
											legend: {  
												enabled: false  
											}  
										}  
									}]  
								}
							});
						})
			})
		},
        get() {
            this.axios.get('arbitrage/input_proccesses/'
				+ '?id=' + this.$route.params.id
				+ '&input_data_info=1')
				.then(res => {
					res.data.map(val => {
						if(val.input_data_id == 0){
							this.items.push(
								{ key: 'Input Data', value: 'Trigger by Specific Data' },
							);
						} else {
							this.items.push(
								{ key: 'Input Data', value: val.input_data_info.name },
							);
						}
						switch(val.is_active){
							case true:
								val.is_active = 'Active';
								break;
							case false:
								val.is_active = 'Inactive';
								break;
						}
						if(val.updated_at != '0001-01-01T00:00:00Z'){
							val.updated_at = this.$moment(val.updated_at).format('YYYY-MM-DD HH:mm:ss');
						}
						if(val.last_activity != '0001-01-01T00:00:00Z'){
							val.last_activity = this.$moment(val.last_activity).format('YYYY-MM-DD HH:mm:ss');
						}
						val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
						this.items.push(
							{ key: 'ID', value: val.id },
							{ key: 'Name', value: val.name },
							{ key: 'Last Data', value: val.last_data },
							{ key: 'Precision', value: val.data_precision },
							{ key: 'Display Priority', value: val.sort_num },
							{ key: 'State', value: val.is_active },
							{ key: 'Description', value: val.detail },
							{ key: 'Last Activity', value: val.last_activity },
							{ key: 'Created at', value: val.created_at },
							{ key: 'Updated at', value: val.updated_at },
						);
					})
				})
		},
        redirect_to(route) {
            this.$router.push(route);
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
